import {createRef, useContext, useEffect, useState} from "react";
import {NodeCareerTeaser} from "../nodes/node--career";
import {Dictionary, DrupalProductNode} from "../../../types/hygiena-types";
import {DrupalNode} from "next-drupal";
import classNames from "classnames";
import {Pagination} from "../../organisms/widget--pagination";
import dynamic from "next/dynamic";
import {DictionaryContext} from "../../../context/dictionary-context";
const FormattedText = dynamic(() => import("../../formatted-text").then((mod) => mod.FormattedText));

export function ParagraphCareersGrid({paragraph, ...props}) {
  // @todo all of this is borrowed from the ProductGrid, let's find a way to abstract it.
  let careers: DrupalNode[] = [];
  const nodes = paragraph?.careers ?? [];
  const ref = createRef<HTMLDivElement>();
  const careersRef = createRef<HTMLDivElement>();
  let hasFilters;
  let availableFilterIds: string[] = [];
  let regions: {[key: string]: string}[]|string[] = [];
  let locations: {[key: string]: string}[]|string[] = [];
  let employments: {[key: string]: string}[]|string[] = [];
  let presences: {[key: string]: string}[]|string[] = [];
  let departments: {[key: string]: string}[]|string[] = [];
  const [regionFilter, setRegionFilter] = useState<string>();
  const [employmentFilter, setEmploymentFilter] = useState<string>();
  const [presenceFilter, setPresenceFilter] = useState<string>();
  const [departmentFilter, setDepartmentFilter] = useState<string>();
  const [locationFilter, setLocationFilter] = useState<string>();
  const [page, setPage] = useState(0);
  const pageLimit = 9;
  const t = useContext<Dictionary>(DictionaryContext);

  // Build a list of available filters for the career list.
  if (nodes?.length) {
    for (let career of nodes) {
      if(career?.field_career_department) {
        let department = career?.field_career_department;
        if (!departments[department.id]) {
          departments[department.id] = department;
        }
      }
      if(career?.field_career_employment_type) {
        let employment = career?.field_career_employment_type;
        if (employment?.id && !employments[employment.id]) employments[employment.id] = employment;
        else if (Array.isArray(employment)) {
          for (const item of employment) {
            if (item?.id && !employments[item.id]) employments[item.id] = item;
          }
        }
      }
      // Locations require a combination of presence and location.
      let presence = career?.field_career_presence;
      if(career?.field_career_location) {
        let location = career?.field_career_location;
        if (location?.id) {
          if (presence?.id) {
            let locationId = presence?.id ? `${location.id}:${presence.id}` : location.id;
            if (!locations[locationId]) locations[locationId] = {key: locationId, location, presence: presence};
          }
          else if (Array.isArray(presence)) {
            for (const item of presence) {
              let locationId = item?.id ? `${location.id}:${item.id}` : location.id;
              if (!locations[locationId]) locations[locationId] = {key: locationId, location, presence: item};
            }
          }
          else if (!locations[location?.id]) locations[location.id] = {key: location.id, location};
        }
        else if (Array.isArray(location)) {
          for (const item of location) {
            if (!item?.id) continue;
            if (presence?.id) {
              let locationId: string = presence?.id ? `${item.id}:${presence.id}` : item.id;
              if (!locations[locationId]) locations[locationId] = {key: locationId, location: item, presence};
            }
            else if (Array.isArray(presence)) {
              for (const presenceItem of presence) {
                let locationId: string = presenceItem?.id ? `${item.id}:${presenceItem.id}` : item.id;
                if (!locations[locationId]) locations[locationId] = {key: locationId, location: item, presence: presenceItem};
              }
            }
            else if (item?.id && !locations[item.id]) locations[item.id] = {key: item.id, location: item};
          }
        }
      }
      if(career?.field_career_region) {
        let region = career?.field_career_region;
        if (region?.id && !regions[region.id]) regions[region.id] = region;
        else if (Array.isArray(region)) {
          for (const item of region) {
            if (item?.id && !regions[item.id]) regions[item.id] = item;
          }
        }
      }
    }

    // Sort All Filters.
    regions = Object.values(regions);
    regions.sort((a, b) => {
      if ((a?.name ?? a?.title) < (b?.name ?? b?.title)) return -1;
      if ((a?.name ?? a?.title) > (b?.name ?? b?.title)) return 1;
      return 0;
    });
    departments = Object.values(departments);
    // Locations we need to sort by name AND presence name.
    locations = Object.values(locations);
    locations.sort((a, b) => {
      const titleA = `${a?.location?.title} ${a?.presence?.name}`;
      const titleB = `${b?.location?.title} ${b?.presence?.name}`;
      return titleA.localeCompare(titleB);
    });
    departments = Object.values(departments);
    departments.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    employments = Object.values(employments);
    employments.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    presences = Object.values(presences);
    presences.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  }

  /**
   * Get all products for the current term, filtered by test target if present.
   */
  function getTermProducts(): DrupalProductNode[] {
    // If we are filtering the page by a test target, show only products that
    // have that test target or is a child of the test target.
    // Now filter by any selected filters: use AND method.
    let careers = nodes;
    availableFilterIds = [];

    if (departmentFilter || presenceFilter || employmentFilter || regionFilter) {
      careers = nodes.filter(product => {
        let filteredCareer : null|DrupalNode = product;
        if (departmentFilter && filteredCareer && departmentFilter !== filteredCareer?.field_career_department?.id) {
          filteredCareer = null;
        }
        // Presence filter - cardinality of 1.
        if (presenceFilter && filteredCareer && !Array.isArray(filteredCareer?.field_career_presence) && presenceFilter !== filteredCareer?.field_career_presence?.id) {
          filteredCareer = null;
        }
        // Presence filter - unlimited cardinality.
        if (presenceFilter && filteredCareer && Array.isArray(filteredCareer?.field_career_presence)) {
          if (!filteredCareer?.field_career_presence.filter(item => item?.id === presenceFilter)?.length) {
            filteredCareer = null;
          }
        }
        // Location filter - cardinality of 1.
        if (locationFilter && filteredCareer && !Array.isArray(filteredCareer?.field_career_location) && locationFilter !== filteredCareer?.field_career_location?.id) {
          filteredCareer = null;
        }
        // Location filter filter - unlimited cardinality.
        if (locationFilter && filteredCareer && Array.isArray(filteredCareer?.field_career_location)) {
          if (!filteredCareer?.field_career_location.filter(item => item?.id === locationFilter)?.length) {
            filteredCareer = null;
          }
        }
        // Employment Type filter - cardinality of 1.
        if (employmentFilter && filteredCareer && !Array.isArray(filteredCareer?.field_career_employment_type) && employmentFilter !== filteredCareer?.field_career_employment_type?.id) {
          filteredCareer = null;
        }
        // Employment Type filter - unlimited cardinality.
        if (employmentFilter && filteredCareer && Array.isArray(filteredCareer?.field_career_employment_type)) {
          if (!filteredCareer?.field_career_employment_type.filter(item => item?.id === employmentFilter)?.length) {
            filteredCareer = null;
          }
        }
        // Combined filter for region/location.
        if (regionFilter && filteredCareer) {// && regionFilter !== filteredCareer?.field_career_location ?.id) {
          let combined = false;
          if (regionFilter === filteredCareer?.filter_career_location?.id) combined = true;
          if (regionFilter === filteredCareer?.filter_career_region?.id) combined = true;
          if (Array.isArray(filteredCareer?.field_career_location)) {
            if (filteredCareer?.field_career_location?.filter(item => item?.id === regionFilter)?.length) combined = true;
          }
          if (Array.isArray(filteredCareer?.field_career_region)) {
            if (filteredCareer?.field_career_region?.filter(item => item?.id === regionFilter)?.length) combined = true;
          }
          if (!combined) {
            filteredCareer = null;
          }
        }
        if (!filteredCareer) {
          return null;
        }

        // Update Available Filters.
        // Department.
        if(filteredCareer?.field_career_department?.id) {
          availableFilterIds.push(filteredCareer.field_career_department.id);
        }
        // Presence.
        if (filteredCareer?.field_career_presence?.id) {
          availableFilterIds.push(filteredCareer.field_career_presence.id)
        }
        else if (Array.isArray(filteredCareer?.field_career_presence)) {
          for (const presence of filteredCareer.field_career_presence) {
            availableFilterIds.push(presence.id);
          }
        }
        // Location.
        if (filteredCareer?.field_career_location?.id) {
          availableFilterIds.push(filteredCareer.field_career_location.id);
        }
        else if (Array.isArray(filteredCareer?.field_career_location)) {
          for (const location of filteredCareer.field_career_location) {
            availableFilterIds.push(location.id);
          }
        }
        // Employment Type.
        if (filteredCareer?.field_career_employment_type?.id) {
          availableFilterIds.push(filteredCareer.field_career_employment_type.id);
        }
        else if (Array.isArray(filteredCareer?.field_career_employment_type)) {
          for (const employment of filteredCareer?.field_career_employment_type) {
            availableFilterIds.push(employment.id);
          }
        }
        // Region.
        if (filteredCareer?.filter_career_region?.id) {
          availableFilterIds.push(filteredCareer.filter_career_region.id);
        }
        else if (Array.isArray(filteredCareer?.field_career_region)) {
          for (const region of filteredCareer.field_career_region) {
            availableFilterIds.push(region.id);
          }
        }
        // Locations require a combination of presence and location, add these to the available filter ids.
        // This functionality is slightly duplicated.
        let presence = filteredCareer?.field_career_presence;
        if(filteredCareer?.field_career_location) {
          let location = filteredCareer?.field_career_location;
          if (location?.id) {
            if (presence?.id) {
              let locationId = presence?.id ? `${location.id}:${presence.id}` : location.id;
              availableFilterIds.push(locationId);
            }
            else if (Array.isArray(presence)) {
              for (const item of presence) {
                let locationId = item?.id ? `${location.id}:${item.id}` : location.id;
                availableFilterIds.push(locationId);
              }
            }
          }
          else if (Array.isArray(location)) {
            for (const item of location) {
              if (!item?.id) continue;
              if (presence?.id) {
                let locationId: string = presence?.id ? `${item.id}:${presence.id}` : item.id;
                availableFilterIds.push(locationId);
              }
              else if (Array.isArray(presence)) {
                for (const presenceItem of presence) {
                  let locationId: string = presenceItem?.id ? `${item.id}:${presenceItem.id}` : item.id;
                  availableFilterIds.push(locationId);
                }
              }
              else if (item?.id && !locations[item.id]) locations[item.id] = {key: item.id, location: item};
            }
          }
        }

        return filteredCareer;
      });

      availableFilterIds = availableFilterIds.filter((item, index, array) => array.indexOf(item) === index);
    }

    // Get a list of available filters.

    return careers;
  }

  /**
   * Locations have to be filtered by both location and presence.
   *
   * @param value
   */
  function filterLocation(value: string) {
    const parts = value?.split(":");
    setLocationFilter(parts?.[0]);
    setPresenceFilter(parts?.[1]);
  }

  function changePage(page) {
    setPage(page);
    if (ref?.current) ref?.current?.scrollIntoView({behavior: 'smooth'});
  }

  /**
   * Matches the title height of career node teasers.
   */
  function matchTitleHeight() {
    if (!careersRef?.current) return;
    let maxHeight = 0;
    const titles = careersRef.current.querySelectorAll('.node-title');
    if (titles?.length) {
      // Get the title with the largest height, then set all titles to use that height as the minimum.
      for (let i = 0; i < titles?.length; i++) {
        let element = titles[i] as HTMLDivElement;
        if (element?.offsetHeight && element?.offsetHeight > maxHeight) maxHeight = element.offsetHeight;
      }
      for (let i = 0; i < titles?.length; i++) {
        let element = titles[i] as HTMLDivElement;
        if (element?.style) element.style.minHeight = `${maxHeight}.px`;
      }
    }
  }

  /**
   * Checks if an option is disabled or not based upon current filters.
   *
   * @param id
   *   The id.
   * @param type
   *   The type.
   */
  function optionIsDisabled(id: string, type?: string) {
    const activeFilters = [departmentFilter, presenceFilter, employmentFilter, regionFilter, locationFilter].filter(item => item?.length);
    const singleFilter = activeFilters?.length < 2;

    if (singleFilter && regionFilter && type === "region") return false;
    else if (singleFilter && departmentFilter && type === "department") return false;
    else if (singleFilter && presenceFilter && type === "presence") return false;
    else if (singleFilter && employmentFilter && type === "employment") return false;
    else if (singleFilter && locationFilter && type === "location") return false;
    else if (activeFilters?.length === 2 && presenceFilter && locationFilter && type === "location") {
      return false;
    }

    if (availableFilterIds?.length) return !availableFilterIds.includes(id);
    return false;
  }

  useEffect(() => {
    matchTitleHeight();
  }, [careers]);

  careers = getTermProducts();
  hasFilters = (departments?.length || presences?.length || employments?.length || regions?.length || locations?.length) > 1;

  return (
    <>
      {(hasFilters && nodes.length > 4) ? (
        <div className={classNames("paragraph--careers-grid--filters flex md:justify-end mb-6 pt-2 scroll-m-20")} ref={ref}>
          <div className="w-full lg:w-auto">
            <span className="pr-2 mb-4 md:mb-0 block lg:inline">{`${t?.careers?.filter_jobs}`}:</span>
            {regions?.length > 1 ? (
              <select className="border-black border mb-2 lg:mb-0 md:mx-1 p-1 rounded-sm w-full lg:w-auto block lg:inline" value={regionFilter} onChange={event => { setRegionFilter(event.target.value); setPage(0); }}>
                <option value={""}>{`${t?.careers?.all_regions}`}</option>
                {Object.keys(regions).map(key => {
                  const target = regions[key];
                  return <option value={target.id} key={target.id} disabled={optionIsDisabled(target.id, "region")}>{target?.field_place_address?.country_code ? `${target?.title ?? target?.name} (${target.field_place_address?.country_code})` : target?.title ?? target?.name}</option>
                })}
              </select>
            ): <></>}
            {locations?.length > 1 ? (
              <select className="border-black border mb-2 lg:mb-0 md:mx-1 p-1 rounded-sm w-full lg:w-auto block lg:inline" value={presenceFilter ? `${locationFilter}:${presenceFilter}` : locationFilter} onChange={event => { filterLocation(event.target.value); setPage(0); }}>
                <option value={""}>{`${t?.careers?.all_locations}`}</option>
                {Object.keys(locations).map(key => {
                  const target = locations[key].location;
                  const presence = locations[key].presence;
                  if (!presence) {
                    return <option value={locations[key].key} key={locations[key].key} disabled={optionIsDisabled(locations[key].key, "location")}>{target?.field_place_address?.country_code ? `${target?.title ?? target?.name}, ${target.field_place_address?.country_code}` : target?.title ?? target?.name}</option>
                  }
                  return <option value={locations[key].key} key={locations[key].key} disabled={optionIsDisabled(locations[key].key, "location")}>{target?.field_place_address?.country_code ? `${target?.title ?? target?.name}, ${target.field_place_address?.country_code}` : target?.title ?? target?.name} ({presence.name})</option>
                })}
              </select>
            ): <></>}
            {employments?.length > 1 ? (
              <select className="border-black border mb-2 lg:mb-0 md:mx-1 p-1 rounded-sm w-full lg:w-auto block lg:inline" value={employmentFilter} onChange={event => { setEmploymentFilter(event.target.value); setPage(0); }}>
                <option value={""}>{`${t?.careers?.all_employment}`}</option>
                {Object.keys(employments).map(key => {
                  const target = employments[key];
                  return <option value={target.id} key={target.id} disabled={optionIsDisabled(target.id, "employment")}>{target.name}</option>
                })}
              </select>
            ): <></>}
            {departments?.length > 1 ? (
              <select className="border-black border mb-2 lg:mb-0 md:mx-1 p-1 rounded-sm w-full lg:w-auto block lg:inline" value={departmentFilter} onChange={event => { setDepartmentFilter(event.target.value); setPage(0); }}>
                <option value={""}>{`${t?.careers?.all_departments}`}</option>
                {Object.keys(departments).map(key => {
                  const target = departments[key];
                  return <option value={target.id} key={target.id} disabled={optionIsDisabled(target.id, "department")}>{target.name}</option>
                })}
              </select>
            ): <></>}
            <button onClick={() => {
              filterLocation("");
              setEmploymentFilter("");
              setDepartmentFilter("");
              setRegionFilter("");
            }} className={classNames(
              "bg-accent-red transition-all border-2 border-accent-red text-hygienaLight ml-2 rounded-sm px-6 py-0.5 self-end hover:bg-white hover:text-accent-red hover:border-accent-red max-md:hidden",
              {
                "opacity-0": !(locationFilter && presenceFilter && departmentFilter && employmentFilter && regionFilter),
                "opacity-100 cursor-pointer": locationFilter || presenceFilter || departmentFilter || employmentFilter || regionFilter,
              },
            )}>{`${t?.documents?.reset}`}</button>
          </div>
        </div>
      ): <></>}

      {careers?.length ? (
        <>
          <div className="paragraph--careers-grid--items grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2" ref={careersRef}>
            {careers.slice(page * pageLimit, (page * pageLimit) + pageLimit).map(item => <NodeCareerTeaser key={`careers-grid--${paragraph?.id}-${item?.id}`} node={item}/>)}
          </div>
          {careers?.length ? (
            <div className="container mx-auto">
              <Pagination count={careers?.length} page={page} limit={pageLimit} changePage={changePage} radius={8}/>
            </div>
          ): <></>}
        </>
      ):(
        <FormattedText processed={paragraph?.field_careers_grid_message?.processed}/>
      )}
    </>
  )
}
import {Button} from "../../atoms/button";
import {DrupalNode} from "next-drupal";
import {absoluteURL} from "../../../lib/absolute-url";
import classNames from "classnames";
import {createRef, useContext, useState} from "react";
import {Link} from "../../atoms/link";
import {Dictionary} from "../../../types/hygiena-types";
import {DictionaryContext} from "../../../context/dictionary-context";

export function NodeCareerTeaser({node}: {node: DrupalNode}) {
  const t = useContext<Dictionary>(DictionaryContext);
  const [show, setShow] = useState<boolean>(false);
  const ref = createRef<HTMLButtonElement>();
  let url = ''
  if (node?.field_career_pdf?.length === 1) {
    url = node?.field_career_pdf[0]?.field_media_file?.uri?.url
      ? absoluteURL(node.field_career_pdf[0].field_media_file.uri.url)
      : node?.field_career_link?.uri;
  }
  else {
    url = node?.field_career_link?.uri ?? '';
  }
  let employment =
    !Array.isArray(node?.field_career_employment_type)
      ? node?.field_career_employment_type?.name
      : node?.field_career_employment_type?.map(item => item.name).join(", ");
  let presence =
    !Array.isArray(node?.field_career_presence)
      ? node?.field_career_presence?.name
      : node?.field_career_presence?.map(item => item.name).join(", ");
  let region: string[] = [];

  // Add location to regions.
  if (node?.field_career_location?.title) {
    region.push(getLocationName(node.field_career_location));
  }
  else if (Array.isArray(node?.field_career_location)) {
    for (const item of node.field_career_location.sort((a, b) => a?.name?.localeCompare(b?.name))) {
      region.push(getLocationName(item));
    }
  }

  // Add region to regions.
  if (node?.field_career_region?.name) {
    region.push(node.field_career_location.name);
  }
  else if (Array.isArray(node?.field_career_region)) {
    for (const item of node.field_career_region.sort((a, b) => a?.name?.localeCompare(b?.name))) {
      region.push(item?.name);
    }
  }

  function getLocationName(field) {
    if (!field?.title) return "";
    return field?.field_place_address?.country_code ? `${field.title} (${field.field_place_address.country_code})` : field.title;
  }

  return (
    <div className={classNames("node--career--teaser bg-white p-6 drop-shadow-sm rounded-md grid", {"z-10": show})} style={{gridTemplateRows: "auto 1fr auto"}}>
      <div className="node-title text-2xl text-center font-semibold mb-6">{node?.title}{node?.field_career_mfd ? ` (m/f/d)` : ""}</div>
      <div className="mb-6 leading-relaxed">
        <div>{`${t?.careers?.department}`}: {node?.field_career_department?.name}</div>
        <div>{`${t?.careers?.employment}`}: {employment}</div>
        <div>{`${t?.careers?.presence}`}: {presence}</div>
        <div>{`${region?.length > 1 ? t?.careers?.locations : t?.careers?.location}`}: {region?.join(", ")}</div>
      </div>
      {node?.field_career_pdf?.length > 1 ? (
        <div className="w-full relative text-center text-primary font-semibold" onMouseLeave={() => setShow(false)} onClick={() => setShow(!show)}>
          <Button buttonRef={ref} callback={() => setShow(!show)} icon={"chevron-down"} position={"right"} className="h-full text-center w-full !p-1" containerClassName="w-full h-full" color={"primary"} invert={true} hover={{color: "primary"}} responsiveIcon={true}>{t?.careers?.see_job_description}</Button>
          <ul
            className={classNames(
              "absolute bg-white overflow-auto transition-all w-full z-10 scroll-primary",
              {"max-h-0": !show},
              {"max-h-[250px] transition-all": show}
            )}>
            {node.field_career_pdf.map((item, index) => (
              <li
                key={`job-description-${node.id}-${index}-${item.id}`}
                className="py-2 border-primary border-2 cursor-pointer border-b-0 first:border-t-0 last:border-b-2 rounded-[2px] leading-tight bg-white hover:bg-primary hover:text-white transition-all duration-150">
                <Link href={absoluteURL(item?.field_media_file?.uri?.url) ?? "/"} target="_blank" className="hover:text-white w-full block h-full">
                  {`${item?.field_language?.name ? item?.field_language?.name : t?.careers?.english}`}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ): (
        <>
          {url && (
            <Button
              color={"white"}
              href={url}
              target={"_blank"}
              invertColor={"primary"}
              invert={true}
              hover={{color: "primary"}}
              className="job-description-detail w-full !py-1 text-primary border-primary" containerClassName="w-full flex flex-col justify-end">
              {`${t?.careers?.see_job_description}`}
            </Button>
          )}
        </>
      )}
    </div>
  );
}
